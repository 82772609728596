import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './AntiHero.scss';
import logo from '../../assets/logo.png';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';

export default function AntiHero() {
    return (
        <div className='anti-hero container-fluid'>
            {/* Navbar */}
            <Navbar collapseOnSelect expand="lg" className='navbar' bg="dark" variant="dark">
                <Container>
                <Navbar.Brand href="#hero" className='text-white'>
                    <img src={logo} alt='logo' className='logo' />
                    <span>Jason Vandeyar</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav className='navlist'>
                        <HashLink className='navitems nav-items' to="/#hero">Home</HashLink>
                        <HashLink className='navitems nav-items' to='/#about'>About</HashLink>
                        <HashLink className='navitems nav-items' to="/#projects">Projects</HashLink>
                        <HashLink className='navitems btn btn-outline-white px-3' to="#contact">Contact</HashLink>
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* Content */}
            <div className='container content text-white'>
                <div>
                    <motion.div initial={{ x: '-1000px' }} animate={{ x: 0 }} transition={{ duration: 1.5 }} viewport={{ once: true }}>
                        <h1 className='pb-3 text-center'>Error</h1>
                        <h1 className='pb-3'>Content Not Found</h1>
                    </motion.div>
                </div>
            </div>    
        </div>
    )
}

import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faCss3Alt } from '@fortawesome/free-brands-svg-icons';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faPhp } from '@fortawesome/free-brands-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import jquery from '../assets/jquery-icon.png';
import adobexd from '../assets/adobe-xd.png';
import materialize from '../assets/materialize-css.png';

export const progress_icons = [
    {
        id: 0,
        icon: faJs,
        name: 'JavaScript',
        width: '60'
    },
    {
        id: 1,
        icon: faReact,
        name: 'React JS',
        width: '50'
    },
    {
        id: 2,
        icon: faCss3Alt,
        name: 'CSS 3',
        width: '60'
    },
    {
        id: 3,
        icon: faBootstrap,
        name: 'Bootstrap',
        width: '65'
    },
    {
        id: 4,
        icon: faHtml5,
        name: 'HTML 5',
        width: '70'
    },
    {
        id: 5,
        icon: faPhp,
        name: 'PHP',
        width: '60'
    },
    {
        id: 6,
        icon: faDatabase,
        name: 'MySQL',
        width: '50'
    },
];

export const progress_images = [
    {
        id: 7,
        src: jquery,
        name: 'JQuery',
        width: '45',
        img_width: '16',
        img_height: '16'
    },
    {
        id: 8,
        src: materialize,
        name: 'Materialize',
        width: '45',
        img_width: '23',
        img_height: ''
    },
    {
        id: 9,
        src: adobexd,
        name: 'Adobe XD',
        width: '45',
        img_width: '16',
        img_height: '16'
    }
]
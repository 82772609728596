import React from 'react';
import './About.scss';
import frontend from '../../assets/front-end.png';
import backend from '../../assets/backend.png';
import design from '../../assets/design.png';
import mobile from '../../assets/mobile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { progress_images, progress_icons } from '../../data/progress';

export default function About() {

  return (
    <div id='about' className='container'>
      <div className="header-container">
        <h2 className='text-center fw-semibold fs-1'>ABOUT</h2>
        <div className='header-bar'></div>        
      </div>
      <div className='row'>
        <div className='col-lg-6 breakdown-container'>
          <p className='text-center'>A Full-Stack Web Developer with 4+ years experience. If you're interested in getting an Awesome, User Friendly website built. Or maybe you're looking to start a new app. <span className='text-black fw-semibold'>Then I'm your guy.</span></p>
          <div className='row'>
            <motion.div className='col-md-3' initial={{ x: '-1000' }} whileInView={{ x: 0 }} viewport={{ once: true }} transition={{ duration: 1.5 }}>
              <img className='d-block' src={frontend} alt='Front-end icon' /> 
              <figcaption className='fw-bold text-black'>Front-End</figcaption>
            </motion.div>
            <motion.div className='col-md-3' initial={{ x: '-1000' }} whileInView={{ x: 0 }} viewport={{ once: true }} transition={{ duration: 1.5 }}>
              <img className='d-block' src={backend} alt='Back-end icon' />
              <figcaption className='fw-bold text-black'>Back-End</figcaption>
            </motion.div>
          </div>
          <div className="row">
            <motion.div className='col-md-3' initial={{ x: '-1000' }} whileInView={{ x: 0 }} viewport={{ once: true }} transition={{ duration: 1.5 }}>
              <img className='d-block' src={design} alt='Design icon' />
              <figcaption className='text-center fw-bold text-black'>Design</figcaption>
            </motion.div>
            <motion.div className='col-md-3' initial={{ x: '-1000' }} whileInView={{ x: 0 }} viewport={{ once: true }} transition={{ duration: 1.5 }}>
              <img className='d-block' src={mobile} alt='Mobile icon' />
              <figcaption className='text-center fw-bold text-black'>Mobile</figcaption>
            </motion.div>
          </div>
        </div>
       {/* Progress Bars */}
        <motion.div className='col-lg-6' initial={{ x: '4000' }} whileInView={{ x: 0 }} viewport={{ once: true }} transition={{ duration: 1.7 }}>
          {progress_icons.map(item => {
            return (
              <div key={item.id} className="progress">
                <div className="progress-bar bg-black progress-description" role="progressbar" aria-label="Segment one" style={{width: '21%'}} aria-valuenow="21" aria-valuemin="0" aria-valuemax="100">
                  <p><FontAwesomeIcon icon={item.icon} /> {item.name}</p>
                </div>
                <motion.div className="progress-bar bg-grey" initial={{ width: '0' }} whileInView={{ width: `${item.width}%` }} transition={{ duration: 1 }} viewport={{ once: true }} role="progressbar" aria-label={item.name} aria-valuenow={item.width} aria-valuemin="0" aria-valuemax="100"></motion.div>
              </div>
            )
          })}
          {progress_images.map(item => {
            return (
              <div key={item.id} className="progress">
                <div className="progress-bar bg-black progress-description" role="progressbar" aria-label="Segment one" style={{width: '21%'}} aria-valuenow="21" aria-valuemin="0" aria-valuemax="100">
                  <p><img src={item.src} alt={item.name} width={item.img_width} height={item.img_height} /> {item.name}</p>
                </div>
                <motion.div className="progress-bar bg-grey" initial={{ width: '0' }} whileInView={{ width: `${item.width}%` }} transition={{ duration: 1 }} viewport={{ once: true }} role="progressbar" aria-label="JQuery" aria-valuenow={item.width} aria-valuemin="0" aria-valuemax="100"></motion.div>
              </div>
            )
          })}
        </motion.div>       
      </div>
    </div>
  )
}

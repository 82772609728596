export const projects = [
    {
        id: 0,
        tech_stack: 'PHP / Bootstrap',
        link: 'https://jv-webdeveloper.co.za/thestory',
        button_text: 'View Project'
    },
    {
        id: 1,
        tech_stack: 'React JS / React Bootstrap',
        link: 'https://jv-webdeveloper.co.za/music-player',
        button_text: 'View Project'
    },
    {
        id: 2,
        tech_stack: 'Materialize',
        link: 'https://jv-webdeveloper.co.za/quazzu',
        button_text: 'View Project'
    },
    {
        id: 3,
        tech_stack: 'React JS / React Bootstrap',
        link: 'https://balungisisolutions.co.za/',
        button_text: 'View Site'
    }
];

import React from 'react';
import About from '../../components/about/About';
import Contact from '../../components/contact/Contact';
import Hero from '../../components/hero/Hero';
import Projects from '../../components/projects/Projects';
import Footer from '../../components/footer/Footer';
import './Home.scss';

export default function Home() {
  return (
    <div>
      <Hero />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  )
}

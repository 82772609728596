import React from 'react';
import './Footer.scss';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

export default function Footer() {
  return (
    <div className='socials pt-4 pb-2'>
      {/* Socials */}
      <motion.div initial={{ x: '-1000' }} whileInView={{ x: 0 }} transition={{ duration: 1 }} viewport={{ once: true }} className="social-container">
        <a href='mailto:info@jv-webdeveloper.co.za' rel='noreferrer'><FontAwesomeIcon className='icon' size='lg' icon={faEnvelope} /></a>
        <a href='https://twitter.com' target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' size='lg' icon={faTwitter} /></a>
        <a href='https://github.com/jv55-developer' target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' size='lg' icon={faGithub} /></a>
        <a href='https://www.linkedin.com/in/jason-vandeyar-7a67741b6/' target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' size='lg' icon={faLinkedinIn} /></a>
      </motion.div>
      <motion.span initial={{ x: '-1000' }} whileInView={{ x: 0 }} transition={{ delay: 1, duration: 1.3 }} viewport={{ once: true }} className='text-white'>2022</motion.span>
    </div>
  )
}

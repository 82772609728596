import React from 'react';
import './Projects.scss';
import { motion } from 'framer-motion';
import { projects } from '../../data/projects';

export default function Projects() {
  return (
    <div id='projects' className='container'>
      <div className="header-container">
        <h2 className='text-center fw-semibold fs-1'>PROJECTS</h2>
        <div className='header-bar'></div>        
      </div>
      <div className='row'>
        {projects.map(item => {
          return (
            <motion.div key={item.id} className='col-lg-4 card-container' initial={{ y: '1000' }} whileInView={{ y: 0 }} viewport={{ once: true }} transition={{ duration: 1.7 }}>
              <div className='card-project'>
                <div className='card-content'>
                  <p>{item.tech_stack}</p>
                  <a href={item.link} target='_blank' rel="noreferrer" className='btn btn-outline-black'>{item.button_text}</a>
                </div>
              </div>
            </motion.div>
          )
        })}
      </div>
    </div>
  )
}

import React from 'react';
import AntiHero from '../../components/anti_hero/AntiHero';
import './Error.scss';

export default function Error() {
  return (
    <div>
      <AntiHero />
    </div>
  )
}

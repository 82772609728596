import React from 'react';
import './Contact.scss';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { ThreeCircles } from  'react-loader-spinner';
import { send } from '@emailjs/browser';

export default function Contact() {
  const [ fullName, setFullName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ cell, setCell ] = useState('');
  const [ subject, setSubject ] = useState('');
  const [ message, setMessage ] = useState('');

  const [ msg, setMsg ] = useState(false);
  const [ alrt, setAlrt ] = useState('');
  const [ alrtMsg, setAlrtMsg ] = useState('');
  const [ sending, setSending ] = useState(false);

  let supplierParams = {
    fullName,
    email,
    cell,
    subject,
    message
  }

  let clientParams = {
    fullName,
    email
  };

  const alertProps = (_1, _2, _3, _4) => {
    setSending(_1);
    setMsg(_2);
    setAlrt(_3);
    setAlrtMsg(_4);   

    setFullName('');
    setEmail('');
    setCell('');
    setSubject('');
    setMessage('');

    setTimeout(() => {
        setMsg(false);
    }, 5000)
  }

  const submitForm = (e) => {
    e.preventDefault();
    setSending(true);

    send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_SUPPLIER_TEMPLATE_ID, supplierParams, process.env.REACT_APP_PUBLIC_KEY)
        .then(res => {
            send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_CLIENT_TEMPLATE_ID, clientParams, process.env.REACT_APP_PUBLIC_KEY)
                .then(res => {
                    alertProps(false, true, 'success', 'Your contact request was successfully sent!');                     
                }, err => {
                    alertProps(false, true, 'danger', 'Unable to send request.');
                })
        }, err => {
            alertProps(false, true, 'danger', 'Unable to send request.');
        })
  }

  return (
    <div id='contact' className='contact-container'>
      <div className='container border-bottom border-light border-1 pb-4'>
        <div className="header-container pt-5">
          <h2 className='text-center fw-semibold fs-1'>CONTACT</h2>
          <div className='header-bar-white'></div>        
        </div>
        {/* Contact Form */}
        <motion.div initial={{ x: '-1000' }} whileInView={{ x: 0 }} transition={{ duration: 1.3 }} viewport={{ once: true }} className='col-md-4 offset-md-4'>
          <h6 className='pb-4'>Have a question or want to work together?</h6>
            <form onSubmit={submitForm}>
              <div className="mb-3">
                <input type="text" required className="form-control" value={fullName} onChange={e => setFullName(e.target.value)} placeholder='Full Name' />
              </div>
              <div className="mb-3">
                <input type="email" required className="form-control" value={email} onChange={e => setEmail(e.target.value)} placeholder='Email Address' />
              </div>
              <div className="mb-3">
                <input type="number" required className="form-control" value={cell} onChange={e => setCell(e.target.value)} placeholder='Cell Number' />
              </div>
              <div className="mb-3">
                <input type="text" required className="form-control" value={subject} onChange={e => setSubject(e.target.value)} placeholder='Subject' />
              </div>
              <div className="mb-3">
                <textarea required className="form-control" rows='5' value={message} onChange={e => setMessage(e.target.value)} placeholder='Your message'>
                </textarea>
              </div>
              {msg && <div className={`alert alert-${alrt} text-center border-${alrt} rounded-0`} role="alert">
                  {alrtMsg}{(alrt === 'danger') && <span> Please contact <a className='text-primary text-decoration-none fw-bold' href="mailto:info@jv-webdeveloper.co.za">info@jv-webdeveloper.co.za</a></span>}
              </div>}
              {!sending && <button type="submit" className="btn btn-outline-white">SUBMIT</button>}
              {sending && <button disabled type="submit" className="btn btn-outline-white"><ThreeCircles
                  height="20"
                  width="20"
                  color="#ffffff"
                  ariaLabel="three-circles-rotating"
                  radius="1"
                  wrapperStyle={{ display: 'inline' }}
                  visible={true}
              /> SENDING</button>}
          </form>
        </motion.div>
      </div>
    </div>
  )
}
